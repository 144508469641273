.hamburgerMenu {
    height: 3rem;
    width: 100%;
    flex: fit-content;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.HeaderBarContent > .AutoContainer  {
    display : flex;
    flex-Direction: row;
    align-Content: flex-start;
    align-Items: flex-start;
    flex-Wrap: nowrap;
    justify-Content : center;
    width: auto;
    flex : 0;
}

#profile_hamburger_menu {
    position: inherit;
}