.TextButton {
    background: none;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    z-index: 50;
}

.TextButton:hover {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
}

.TextButton > h1:hover {
    text-decoration: underline;
    text-decoration-color: #F2F2F2;
}