.Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    top : calc(100% / 2 - 5em);
    bottom: 50%;
    margin: auto;
    position: absolute;
    padding-top: 12rem;
}

.Background {
    background-image: url("../../assets/images/LoginBackground.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.HeaderBarContent > .TextButton {
    margin-right: 2rem;
    width: 5rem;
}

.HeaderBarContent > button.CustomButton {
    text-align: center;
    margin-right: 2rem;
}

.HeaderBarContent > button.CustomButton > h1 {
    font-weight: 800;
    text-align: center;
    opacity: 1;
}
