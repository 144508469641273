.CustomInput {
    border: none;
    outline: none;
    background: #F2F2F2;
    box-shadow: 0px 4px 0px #3B8CB9;
    -moz-box-shadow: 0px 4px 0px #3B8CB9;
    -webkit-box-shadow: 0px 4px 0px #3B8CB9;
    border-radius: 1em;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    width: 70%;
    height: 3em;
}

input[type="number"] {
    font-size: 14px;
}

input[type="text"] {
    font-size: 14px;
}

input[type="password"] {
    font-size: 14px;
}

.CustomInput:focus:active {
    border: none;
    outline: none;
    background: #F2F2F2;
    box-shadow: 0px 4px 0px #3B8CB9;
    -moz-box-shadow: 0px 4px 0px #3B8CB9;
    -webkit-box-shadow: 0px 4px 0px #3B8CB9;
    border-radius: 1.5em;
    -moz-border-radius: 1.5em;
    -webkit-border-radius: 1.5em ;
}

.CustomInputContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: auto;
}

.CustomInputContainer > h1 {
    font-weight: 900;
}
