.Background {
    background-image: url("../../assets/images/LoginBackground.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

#videoListContainerColumn {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    margin: auto;
}

#videoListContainerRow {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin-left: 3em;
    overflow: visible;
    overflow-y: hidden;
    overflow-x: auto;
}