.detector {
    display: none;
    position: absolute;
    width: 256px;
    height: 256px;
    top: 0em;
    left: 50em;
    right: 0;
    bottom: 0;
    margin: auto;
}
.detector_video {
    display: flex;
    align-items: center;
}
.detector_canvas {
    justify-content: center;
    justify-self: center;
    align-self: center;
    display: flex;
}
