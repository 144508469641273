.HeaderBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 5.5em;
    max-height: 7.5em;
    opacity: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-image: url("/src/assets/images/BlurryBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px;
}

.HeaderBarContent {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.HeaderBarContent > * {
    margin-right: 2rem;
}

