body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

* {
  box-sizing: border-box;

  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', Inter-Regular,
  monospace;
}

h1 {
  font-family: "Monsterrat", sans-serif;
  @import url("./fonts/Inter/Monsterrat-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  --fontSizeLabel : 11;
  --fontSizeText : 16;
  --fontSizeTitle : 19;
}

input[type="text"] {
  font-size:18px;
  font-family: "Inter", sans-serif;
  color: #171616;
  text-align: left;
  padding-left: 5px;
}

input[type="password"] {
  padding-left: 5px;
}
