.Panel {
    width: 28em;
    max-height: 35em;
    background-image: url("/src/assets/images/BlurryBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    flex-wrap: wrap;
}

.AutoContainer > .CustomButton {
    border-radius: 4px;
    width: 8rem;
    height: 3.25rem;
    transition: none;
}

.AutoContainer > button.CustomButton:hover {
    width: 8em;
    height: 3.25em;
}

#LoginContainer {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

#GuestContainer {
    margin-bottom: 2em;
}

#GuestButton {
    height: 2.75rem;
    width: 10rem;
    border-radius: 0.25rem;
}
