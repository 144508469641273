button.CustomButton {
    background: #3B8CB9;
    border-radius: 3rem;
    -webkit-border-radius: 3rem;
    -moz-border-radius: 3rem;
    width: 8rem;
    height: 3.25rem;
    border: none;
    outline: none;
    transition: width 0.5s ease-out, height 0.5s ease-out;
    z-index: 50;
}

button.CustomButton:hover {
    width: 9.5rem;
    height: 3.75rem;
    cursor: pointer;
}

button.CustomButton h1 {
    text-align: -webkit-center;
    text-align: center;
    margin: auto;
}

