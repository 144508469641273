.Panel {
    opacity: 1;
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex: auto;
}

.Panel > .AutoContainer {
    width: 100%;
    height: 20%;
}