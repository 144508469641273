.Panel {
    width: 28em;
    max-height: 35em;
    background-image: url("/src/assets/images/BlurryBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    flex-wrap: wrap;
}

.Panel > h1 {
    font-size: 28px;
    color: white;
    line-height: 76px;
    font-weight: 800;
    letter-spacing: 0.035em;
    margin: 0;
}

.AutoContainer > .CustomButton {
    border-radius: 4px;
    width: 8em;
    height: 3.25em;
    transition: none;
}

.AutoContainer > button.CustomButton:hover {
    width: 8em;
    height: 3.25em;
}

#RegisterButton {
    border-radius: 4px;
    width: 8em;
    height: 3.25em;
    transition: none;
    margin-top : 1.5em;
    margin-bottom: 0.5em;
}

#Surname {
    margin-right: 4.2em;
    margin-left: 0.8em;
    width: 100%
}

#Surname > .CustomInput {
    width: 100%;
}

#Name {
    margin-left: 4.2em;
    margin-right: 0.8em;
    width: 100%;
}

#Name > .CustomInput {
    width: 100%;
}

#City {
    margin-right: 4.2em;
    margin-left: 0.8em;
    width: 100%
}

#City > .CustomInput {
    width: 100%;
}

#Age {
    margin-left: 4.2em;
    margin-right: 0.8em;
    width: 100%;
}

#Age > .CustomInput {
    width: 100%;
}